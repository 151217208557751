<template>
  <div class="info-field-container">
    <div class="title-container">
      <svg-icon src="/icons/information_circle.svg" />
      <h6 class="normal-regular">
        {{ title }}
      </h6>
    </div>
    <div>
      <p class="info-text normal-regular">
        <slot />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['title'],
};
</script>

<style lang="scss">
.info-field-container{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.5rem;
  .info-text{
    margin: 0;
  }
  .title-container{
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    gap:0.7rem;
    svg{
      width: 1.5rem;
      height: auto;
      color: var(--primary-color);
      fill: var(--primary-color);
    }
    h6{
      color: var(--primary-color);
      margin: auto 0;
    }
  }
}

</style>
