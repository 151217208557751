<template>
  <div>
    <Header
      class="flex"
      :back="true"
      route="/controller"
      :text="$t('Components.Generic.back')"
    >
      <h5 class="center-text header-title">
        {{ $t('Components.Controller.edit_position') }}
      </h5>
    </Header>

    <Card position="bottom">
      <SettingsListItem
        class="list-item margin margin-medium margin-bottom"
        :title="$t('Components.Controller.height')"
        @click="$router.push(`/controller/position/${positionId}/height`)"
      >
        <LinkSlotItem
          :text="height"
        />
      </SettingsListItem>
      <SettingsListItem
        class="list-item"
        :title="$t('Components.Controller.name')"
        @click="$router.push(`/controller/position/${positionId}/name`)"
      >
        <LinkSlotItem
          :text="positionId"
        />
      </SettingsListItem>
    </Card>
  </div>
</template>

<script>
import SettingsListItem from '@/components/listitem/settings.list.item.vue';
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import ConnectState from '@/singletons/connect.state.singleton';
import LinkSlotItem from '@/components/linkslotitem/link.slot.item.vue';
import UserState from '@/singletons/user.state.singleton';
import { mmToUnitParsed } from '@/functions/unit.converter.functions';

export default {
  components: {
    SettingsListItem,
    Header,
    LinkSlotItem,
    Card,
  },
  computed: {
    positionId() { return this.$route.params.id; },
    position() { return UserState.profile[this.positionId]; },
    device() { return ConnectState.selectedDevice; },
    measurementSystem() {
      const { MeasurementSystem } = UserState.profile;
      return MeasurementSystem;
    },
    measurementUnit() {
      if (this.measurementSystem === 'METRIC') return 'cm';
      return 'in';
    },
    height() {
      return `${mmToUnitParsed(this.position, this.device.offset)} ${this.measurementUnit}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-item{
  cursor: pointer;
}
</style>
