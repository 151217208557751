<template>
  <div>
    <Header
      class="flex"
      :back="true"
      route="/controller"
      :title="$t('Components.Controller.compatible_desks')"
      :text="$t('Components.Generic.back')"
    >
      <h5 class="center-text header-title">
        {{ $t('Components.Controller.compatible_desks') }}
      </h5>
    </Header>
    <Card position="bottom">
      <DeskImage
        class="image-compatible-desks margin margin-bottom margin-medium-large"
        src="images/compatible_desks.png"
      />
      <div class="compatible-desks-info">
        <h6>
          {{ $t('Components.Controller.which_desks') }}
        </h6>
        <h6 @click="navigate('https://www.rolgroup.com/ergo/solutions/')">
          ROL Ergo
          <svg-icon src="/icons/external_link2.svg" />
        </h6>
        <div class="rol-desks margin margin-bottom margin-medium">
          <p>WORK 1, 2</p>
          <p>COMPACT 1, 3</p>
          <p>COLAB 1, 2</p>
          <p>DESIGN 1</p>
        </div>
        <h6 @click="navigate('https://www.ikea.com/se/sv/cat/skrivbord-18962/')">
          IKEA Bekant
          <svg-icon src="/icons/external_link2.svg" />
        </h6>
        <h6 @click="navigate('https://www.ikea.com/se/sv/p/rodulf-skrivbord-sitt-sta-gra-vit-s99326170/')">
          IKEA Rodulf
          <svg-icon src="/icons/external_link2.svg" />
        </h6>
        <h6 @click="navigate('https://www.ajprodukter.se/serier/modulus-skrivbord/')">
          AJ Produkter {{ $t('Components.Wellness.desk') }} MODULUS
          <svg-icon src="/icons/external_link2.svg" />
        </h6>
        <h6 @click="navigate('https://www.knoll.com/product/k-stand-height-adjustable-tables?section=design')">
          MillerKnoll K.stand {{ '\u2122' }}
          <svg-icon src="/icons/external_link2.svg" />
        </h6>
      </div>
    </Card>
  </div>
</template>

<script>
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import DeskImage from '@/components/desk.image.vue';

export default {
  components: {
    Header,
    Card,
    DeskImage,
  },
  methods: {
    navigate(target) {
      window.location = target;
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/breakpoints.scss';
.image-compatible-desks {
  height: 19rem !important;
}
.compatible-desks-info {
  svg {
    width: 1.8rem;
    height: 1.8rem;
    fill: var(--primary-color) !important;
    color: var(--primary-color);
    padding-bottom: 0.5rem;
    margin-left: 1rem;
  }
  h6 {
    cursor: pointer;
    margin-bottom: 1rem;
  }
  .rol-desks {
    p {
      margin-bottom: 0;
    }
  }
}
</style>
