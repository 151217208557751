<template>
  <div>
    <Header
      class="flex"
      :back="true"
      :route="backPath"
      :text="$t('Components.Generic.back')"
    >
      <h5 class="center-text header-title">
        {{ $t('Components.Controller.edit_position') }}
      </h5>
    </Header>
    <Card>
      <BFormInput
        v-model="name"
        class="margin margin-bottom margin-top margin-medium"
        @keydown="parseKeydown"
      />
    </Card>
    <Card position="bottom">
      <div class="button-edit-group">
        <Button
          variant="danger"
          @click="routeBack"
        >
          {{ $t('Components.Controller.cancel') }}
        </Button>
        <Button
          variant="success"
          @click="updateName"
        >
          {{ $t('Components.Controller.apply') }}
        </Button>
      </div>
    </Card>
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue';
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import AppModel from '@/singletons/application.state.singleton';
import Button from '@/components/button/button.vue';

export default {
  components: {
    Header,
    BFormInput,
    Card,
    Button,
  },
  data() {
    return {
      name: 0,
    };
  },
  computed: {
    positionId() { return Number(this.$route.params.id); },
    positionName() { return AppModel.positions.find(({ id }) => id === this.positionId).name; },
    backPath() { return `/controller/position/${this.positionId}`; },
  },
  created() {
    this.name = this.positionName;
  },
  methods: {
    parseKeydown(e) {
      if (/^\s*\d* \$/.test(e.key)) {
        e.preventDefault();
      }
    },
    routeBack() {
      this.$router.replace(this.backPath);
    },
    async updateName() {
      // await AppController.updatePosition(this.positionId, { name: this.name });
      this.$router.replace('/controller');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-edit-group{
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

</style>
