<template>
  <div>
    <div>
      <Header
        class="flex"
        :back="true"
        :text="$t('Components.Controller.desk_settings')"
      >
        <h5 class="center-text header-title">
          {{ $t('Components.Controller.unit') }}
        </h5>
      </Header>
    </div>
    <!-- Metric option -->
    <div
      class="radio-btn-information"
      @click="handleSelect('METRIC')"
    >
      <RadioButton
        :class="metric_checked ? 'checked' : 'not-checked'"
      />
      <div class="radio-btn-details">
        <h6>{{ $t('Components.Controller.metric') }}</h6>
        <p>{{ $t('Components.Controller.metric_description') }}</p>
      </div>
    </div>
    <!-- Imperial option -->
    <div
      class="radio-btn-information"
      @click="handleSelect('IMPERIAL')"
    >
      <RadioButton
        :class="imperial_checked ? 'checked' : 'not-checked'"
      />
      <div class="radio-btn-details">
        <h6>{{ $t('Components.Controller.imperial') }}</h6>
        <p>{{ $t('Components.Controller.imperial_description') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import RadioButton from '@/components/RadioButton/simple.radio.button.vue';
import Header from '@/components/headers/header.vue';
import UserService from '@/Services/User/user.service';
import ConnectState from '@/singletons/connect.state.singleton';

export default {
  components: {
    RadioButton,
    Header,
  },
  data() {
    return {
      metric_checked: false,
      imperial_checked: false,
      selected: 'METRIC',
    };
  },
  computed: {
    device() { return ConnectState.selectedDevice; },
  },
  async destroyed() {
    const key = 'MeasurementSystem';
    await UserService.saveProfile(key, this.selected);
    this.device.updateUnit(this.selected);
  },
  created() {
    this.metric_checked = (this.device.unit === 'METRIC');
    this.imperial_checked = (this.device.unit === 'IMPERIAL');
  },
  methods: {
    handleSelect(selected) {
      this.metric_checked = (selected === 'METRIC');
      this.imperial_checked = (selected === 'IMPERIAL');
      this.selected = this.metric_checked ? 'METRIC' : 'IMPERIAL';
    },
  },
};

</script>

<style lang="scss">
  .radio-btn-information {
    display: flex;
    margin: 2rem;
    p, h6 {
      color: var(--primary-font-color);
      margin: 0;
    }
    .radio-btn-details {
      margin-left: 1rem;
    }
  }
</style>
