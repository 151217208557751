<template>
  <div class="flex flex-column">
    <Header
      class="flex"
      :back="true"
      :route="backPath"
      :text="$t('Components.Controller.desk_settings')"
    >
      <h5 class="center-text header-title">
        {{ $t('Components.Controller.desk_limits') }}
      </h5>
    </Header>
    <Card position="bottom">
      <div class="content__container">
        <!-- Info row -->
        <div class="content__info__limits">
          <h3>
            {{ currentHeight }}
          </h3>
        </div>
        <!-- image / heightbar -->
        <div class="content__visual">
          <DeskImage
            class="image-top-margin"
            src="images/desk_high_limit.png"
          />
          <div class="height-bar__container">
            <HeightBar
              :device="device"
              :current-height="device.position"
              :high-limit="device.highLimit"
              :low-limit="device.lowLimit"
              :max-height="device.maxHeight"
            />
          </div>
        </div>
        <div>
          <div class="limits__header margin margin-bottom margin-medium-large">
            <h5>{{ $t('Components.Controller.desk_limits') }} </h5>
          </div>
          <LimitItem
            :device="device"
            :title="$t('Components.Controller.high_limit')"
            :limit-value="device.highLimit"
            :limit-active="!highLimitSameAsMaxHeight"
            icon="icons/high_limit.svg"
            @updateLimit="updateHighLimit"
            @clearLimit="clearHighLimit"
          />
          <LimitItem
            :device="device"
            :title="$t('Components.Controller.low_limit')"
            :limit-value="device.lowLimit"
            :limit-active="!lowLimitSameAsMaxHeight"
            icon="icons/low_limit.svg"
            @updateLimit="updateLowLimit"
            @clearLimit="clearLowLimit"
          />
        </div>
      </div>
    </Card>
    <Card
      v-if="device"
      position="bottom"
    >
      <!-- High limit -->
      <div
        v-show="showHighLimitPrompt === true"
        class="limit-texts"
      >
        <p>{{ $t('Components.Controller.highest_limit_reached') }}</p>
      </div>
      <!-- Low limit -->
      <div
        v-show="showLowLimitPrompt === true"
        class="limit-texts"
      >
        <p>{{ $t('Components.Controller.lowest_limit_reached') }}</p>
      </div>
    </Card>
    <Card
      position="bottom no-padding"
      class="flex grow flex-center-h flex-end card no-padding no-border"
    >
      <ControllerHandController
        :device="ConnectState.selectedDevice"
        @mouseoverUp="checkLimits('high')"
        @mouseleaveUp="showHighLimitPrompt = false"
        @mouseoverDown="checkLimits('low')"
        @mouseleaveDown="showLowLimitPrompt = false"
      />
    </Card>
  </div>
</template>

<script>
import DeskImage from '@/components/desk.image.vue';
import ConnectState from '@/singletons/connect.state.singleton';
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import UserState from '@/singletons/user.state.singleton';
import CustomEvent from '@/classes/custom.event.class';
import HeightBar from './components/height.bar.vue';
import LimitItem from './components/limit.item.vue';
import ControllerHandController from './components/controller.device.controller.vue';

export default {
  components: {
    HeightBar,
    DeskImage,
    LimitItem,
    Card,
    Header,
    ControllerHandController,
  },
  data() {
    return {
      ConnectState,
      backPath: '/controller/settings',
      showHighLimitPrompt: false,
      showLowLimitPrompt: false,
    };
  },
  computed: {
    highLimitSameAsMaxHeight() { return Math.abs(this.device.highLimit - this.device.maxHeight) < 5; },
    lowLimitSameAsMaxHeight() { return Math.abs(this.device.lowLimit - this.device.minHeight) < 5; },
    device() { return ConnectState.selectedDevice; },
    measurementSystem() {
      const { MeasurementSystem } = UserState.profile;
      return MeasurementSystem;
    },
    measurementUnit() {
      if (this.measurementSystem === 'METRIC') return 'cm';
      return 'in';
    },
    currentHeight() {
      return `${this.device.getPosition(this.measurementUnit)} ${this.measurementUnit}`;
    },
  },
  methods: {
    checkLimits(key) {
      const { position } = ConnectState.selectedDevice;
      const { highLimit } = ConnectState.selectedDevice;
      const { lowLimit } = ConnectState.selectedDevice;
      if (key === 'high') {
        if (Math.abs(position - highLimit) <= 100) {
          this.showHighLimitPrompt = true;
        }
      } else if (key === 'low') {
        if (Math.abs(position - lowLimit) <= 100) {
          this.showLowLimitPrompt = true;
        }
      }
    },
    updateHighLimit() {
      const highlimit = this.device.getPosition() - this.device.offset;
      if (Math.abs((highlimit - this.device.lowLimit)) <= 50) {
        const evt = new CustomEvent(this.$t('Components.Controller.limits_are_too_close'), 'global_error_message', 'danger');
        evt.dispatch();
        return;
      }
      this.device.updateHighLimit(highlimit);
    },
    clearHighLimit() {
      const highlimit = this.device.maxHeight;
      this.device.updateHighLimit(highlimit);
    },
    updateLowLimit() {
      const lowlimit = this.device.getPosition() - this.device.offset;
      if (Math.abs((this.device.highLimit - lowlimit)) <= 50) {
        const evt = new CustomEvent(this.$t('Components.Controller.limits_are_too_close'), 'global_error_message', 'danger');
        evt.dispatch();
        return;
      }
      this.device.updateLowLimit(lowlimit);
    },
    clearLowLimit() {
      const lowlimit = this.device.minHeight;
      this.device.updateLowLimit(lowlimit);
    },
  },
};
</script>

<style lang="scss">
.content__visual{
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  .image-top-margin{
    margin-top: 3rem;
  }
  .height-bar__container{
    position: absolute;
    right: 0;
    top:0;
    margin-right: 3rem;
    padding: 1rem;
  }
}
.limits__header {
  display: flex;
  align-items: center;
  h5 {
    margin: 0;
    flex-grow: 1;
  }
}
.content__info__limits {
  display: flex;
  justify-content: flex-end;
  .flex-col {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  h6 {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
  }
  h3 {
    margin-bottom:auto;
    display: flex;
    align-items: flex-end;
    font-size: 2rem;
    font-weight: 700;
    color: var(--primary-font-color);
    font-variant-numeric: tabular-nums;
  }
  svg {
    width: 26px;
    height: 26px;
    fill: var(--primary-color)
  }
}
</style>
