<template>
  <div>
    <Header
      class="flex"
      :back="true"
      :route="'/controller/settings'"
      :text="$t('Components.Controller.desk_settings')"
    >
      <h5 class="center-text header-title">
        {{ $t('Components.Controller.calibrate') }}
      </h5>
    </Header>
    <Card>
      <DeskImage
        src="images/desk_calibrate_height.png"
        class="margin margin-medium margin-top margin-bottom"
      />
      <InfoField :title="$t('Components.Controller.calibrate')">
        {{ $t('Components.Controller.calibrate_info') }}
      </InfoField>
    </Card>
    <Card>
      <div class="input-container margin margin-bottom margin-top margin-medium">
        <BFormInput
          v-model="inputHeight"
          class="height-input"
          @keydown="parseKeydown"
        />
        <p>{{ measurementUnit }} </p>
      </div>
    </Card>
    <Card position="bottom">
      <div class="button-edit-group">
        <Button
          variant="danger"
          @click="$router.replace('/controller');"
        >
          {{ $t('Components.Controller.cancel') }}
        </Button>
        <Button
          :disabled="!validNumber"
          variant="success"
          @click="updateOffset"
        >
          {{ $t('Components.Controller.apply') }}
        </Button>
      </div>
    </Card>
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue';
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import Button from '@/components/button/button.vue';
import ConnectState from '@/singletons/connect.state.singleton';
import DeskImage from '@/components/desk.image.vue';
import InfoField from '@/components/info.field.vue';
import { unitToMm } from '@/functions/unit.converter.functions';
import UserState from '@/singletons/user.state.singleton';

export default {
  components: {
    Header,
    BFormInput,
    Card,
    InfoField,
    DeskImage,
    Button,
  },
  data() {
    return {
      inputHeight: 0,
    };
  },
  computed: {
    validNumber() { return Number(this.inputHeight); },
    measurementSystem() {
      const { MeasurementSystem } = UserState.profile;
      return MeasurementSystem;
    },
    measurementUnit() {
      if (this.measurementSystem === 'METRIC') return 'cm';
      return 'in';
    },
    device() { return ConnectState.selectedDevice; },
  },
  created() {
    this.inputHeight = this.device.getPosition(this.measurementUnit);
  },
  methods: {
    parseKeydown(e) {
      if (/^[^\d.]$/.test(e.key)) {
        e.preventDefault();
      }
    },
    async updateOffset() {
      const offset = Math.abs(unitToMm(this.inputHeight) - (this.device.getPosition('mm') - this.device.offset));
      this.device.updateOffset(offset);
      this.$router.replace('/controller/settings');
    },
  },
};
</script>

<style lang="scss" scoped>
.button-edit-group{
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.input-container{
  display: flex;
  gap: 1rem;
  .height-input{
    width: 5rem;
  }
  p{
    margin: auto 0;
  }
}

</style>
