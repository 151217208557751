<template>
  <div>
    <Header
      class="flex"
      :back="true"
      :route="backPath"
      :text="$t('Components.Generic.back')"
    >
      <h5 class="center-text header-title">
        {{ $t('Components.Controller.edit_position') }}
      </h5>
    </Header>
    <Card>
      <div class="input-container margin margin-bottom margin-top margin-medium">
        <BFormInput
          v-model="editHeight"
          class="height-input"
          @keydown="parseKeydown"
        />
        <p>{{ AppController.getMeasurementUnit() }} </p>
      </div>
    </Card>
    <Card position="bottom">
      <div class="button-edit-group">
        <Button
          variant="danger"
          @click="routeBack"
        >
          {{ $t('Components.Controller.cancel') }}
        </Button>
        <Button
          :disabled="(editHeight === positionHeight) || invalidHeight || !validNumber"
          variant="success"
          @click="updateHeight"
        >
          {{ $t('Components.Controller.apply') }}
        </Button>
      </div>
    </Card>
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue';
import Header from '@/components/headers/header.vue';
import Card from '@/components/card/card.vue';
import AppModel from '@/singletons/application.state.singleton';
import Button from '@/components/button/button.vue';
import ConnectState from '@/singletons/connect.state.singleton';

export default {
  components: {
    Header,
    BFormInput,
    Card,
    Button,
  },
  data() {
    return {
      editHeight: 0,
    };
  },
  computed: {
    validNumber() { return Number(this.editHeight); },
    positionId() { return Number(this.$route.params.id); },
    position() { return AppModel.positions.find(({ id }) => id === this.positionId); },
    device() { return ConnectState.selectedDevice; },
    backPath() { return `/controller/position/${this.positionId}`; },
    defaultStand() { return AppModel.positions.find(({ type, isdefault }) => type === 'standing' && isdefault); },
    defaultSit() { return AppModel.positions.find(({ type, isdefault }) => type === 'sitting' && isdefault); },
  },
  created() {
    this.editHeight = this.positionHeight;
  },
  methods: {
    parseKeydown(e) {
      if (/^[^\d.]$/.test(e.key)) {
        e.preventDefault();
      }
    },
    routeBack() {
      const path = `/controller/position/${this.positionId}`;
      this.$router.replace(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.button-edit-group{
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.input-container{
  display: flex;
  gap: 1rem;
  .height-input{
    width: 5rem;
  }
  p{
    margin: auto 0;
  }
}

</style>
