<template>
  <div class="limit__container margin margin-bottom margin-medium-large">
    <div class="limit_information">
      <RoundResourceIcon
        type="resource reverse"
        :src="icon"
      />
      <div class="limit_data">
        <h6>{{ title }}</h6>
        <p>
          {{ height }}
        </p>
      </div>
    </div>
    <div class="kebab-menu">
      <Kebab :options="getKebabOptions()" />
    </div>
  </div>
</template>

<script>
import Kebab from '@/components/kebab.menu.vue';
import RoundResourceIcon from '@/components/icons/round.resource.icon.vue';
import UserState from '@/singletons/user.state.singleton';
import { mmToUnitParsed } from '@/functions/unit.converter.functions';

export default {
  components: {
    Kebab,
    RoundResourceIcon,
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    limitValue: {
      type: Number,
      required: true,
    },
    limitActive: {
      type: Boolean,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    measurementSystem() {
      const { MeasurementSystem } = UserState.profile;
      return MeasurementSystem;
    },
    measurementUnit() {
      if (this.measurementSystem === 'METRIC') return 'cm';
      return 'in';
    },
    height() {
      return this.limitActive
        ? `${mmToUnitParsed(this.limitValue, this.device.offset)} ${this.measurementUnit}`
        : this.$t('Components.Controller.no_limit');
    },
  },
  methods: {
    getKebabOptions() {
      return [
        { text: this.$t('Components.Controller.replace_height'), isDanger: false, action: () => { this.$emit('updateLimit'); } },
        { text: this.$t('Components.Controller.clear_limit'), isDanger: false, action: () => { this.$emit('clearLimit'); } },
      ];
    },
  },
};
</script>

<style lang="scss" >
.limit__container{
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  .limit_information{
    cursor: default;
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;
    gap: 1rem;
    p, h6{
      color: var(--primary-font-color);
      margin: 0;
    }
  }
  .limit_data{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .kebab-menu{
    display: flex;
    margin: auto 0;
  }
}
</style>
